/* globals widgetInit */
import { Map } from 'immutable'
import fetch from 'isomorphic-fetch'
import Cookies from 'js-cookie'
import _ from 'lodash'
import moment from 'moment-timezone'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import { formattedTimesByMinuteDuration } from 'svr/common/TimeUtil'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'
import { ALL_LOCATIONS } from 'widget/dining/utils/convertData'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { CLIENT_PREFERRED_LANGUAGE_COOKIE } from '../utils/constantTypes'

const cleanVenueData = (venueUrlKey, venueInfoResponse, app, widgetSettings, upgradesResponse) => {
  let venueInfo = _.assign({}, camelCaseObject(_.omit(venueInfoResponse, 'tag_groups')), {
    venueUrlKey,
  })
  const venueMap = Map(venueInfoResponse?.venue_map)
    .map(val => val.venue_name)
    .set(ALL_LOCATIONS, widgetInit.settings.text_all_locations)
  venueInfo.venueMap = venueMap

  const isWaitlistMode = app.isWaitlistWidget
  const isLandingPageMode = app.isLandingPageWidget
  const { experienceId } = app.requestParams
  const venueEntities = _.mapValues(venueInfoResponse?.venue_map, camelCaseObject)
  const { search, ui } = cleanSearchAndUIData(widgetSettings, venueInfo, app, isWaitlistMode, experienceId)
  const { tags } = cleanEntitiesData(venueInfoResponse.tag_groups)
  const language = cleanLanguageData(venueInfo, venueEntities, app, search.selectedVenue, isWaitlistMode, isLandingPageMode)
  const formFields = cleanInitialFormData(widgetSettings, venueInfo)
  widgetInit.upsells = upgradesResponse
  venueInfo = _.omit(venueInfo, ['clientTags', 'reservationTags', 'venueMap', 'venueToday', 'tagGroups'])
  return { venueInfo, venueEntities, search, ui, language, formFields, tags }
}

const fetchWidgetData = (baseUrl, venueUrlKey, guestPreferredLanguage, venueQueryString, widgetType, modifyUpgrades) => {
  let url = `${baseUrl}/api-yoa/dining/venue_info?venue_url_key=${venueUrlKey}&widget_type=${widgetType}&modify_upgrades=${modifyUpgrades}`
  if (venueQueryString) {
    url = `${url}&venues=${venueQueryString}`
  }
  if (guestPreferredLanguage) {
    url = `${url}&guest_preferred_lang=${guestPreferredLanguage}`
  }
  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
}

export const getWidgetDataStart = () => ({ type: ActionTypes.LOAD_WIDGET_DATA_START })

export const getWidgetDataFail = errorMessage => ({ type: ActionTypes.LOAD_WIDGET_DATA_ERROR, errorMessage })

const initializeVenueInfoData = venueInfo => ({ type: ActionTypes.INITIALIZE_VENUE_INFO, venueInfo })

const initializeVenueEntitiesData = venueEntities => ({ type: ActionTypes.INITIALIZE_VENUE_ENTITIES, venueEntities })

const initializeSearchData = search => ({ type: ActionTypes.INITIALIZE_SEARCH, search })

const initializeUiData = ui => ({ type: ActionTypes.INITIALIZE_UI, ui })

const initializeLanguageData = language => ({ type: ActionTypes.INITIALIZE_LANGUAGE, language })

const resetFormFieldsData = formFields => ({ type: ActionTypes.RESET_FORM_FIELDS, formFields })
const initializeFormFieldsData = formFields => ({ type: ActionTypes.INITIALIZE_FORM_FIELDS, formFields })

const initializeTagsData = tags => ({ type: ActionTypes.INITIALIZE_TAGS, tags })

const initializeUpgradesData = () => ({ type: ActionTypes.RE_INITIALIZE_UPGRADES })

const getWidgetDataSuccess = () => ({ type: ActionTypes.LOAD_WIDGET_DATA_SUCCESS })

export const tryGetWidgetData = () => (dispatch, getState) => {
  const state = getState()
  const { venueUrlKey } = state.venueInfo
  const { lang, venues } = state.app.requestParams
  const modifyUpgrades = state.modifyReservation.get('isModifyResUpgradesMode')
  const { baseUrl } = state.widgetSettings
  const widgetType = state.preloadedState.get('widgetType')
  dispatch(getWidgetDataStart())
  return fetchWidgetData(baseUrl, venueUrlKey, lang, venues, widgetType, modifyUpgrades)
    .then(response => {
      const cleanedData = cleanVenueData(venueUrlKey, response.data.venue_info, state.app, state.widgetSettings, response.data.upgrades)
      dispatch(initializeVenueInfoData(cleanedData.venueInfo))
      dispatch(initializeVenueEntitiesData(cleanedData.venueEntities))
      dispatch(initializeSearchData(cleanedData.search))
      dispatch(initializeUiData(cleanedData.ui))
      dispatch(initializeLanguageData(cleanedData.language))
      dispatch(initializeFormFieldsData(cleanedData.formFields))
      dispatch(initializeTagsData(cleanedData.tags))
      dispatch(initializeUpgradesData())
      return dispatch(getWidgetDataSuccess())
    })
    .catch(() => dispatch(getWidgetDataFail('Failed to fetch reservation widget data. Please refresh the page.')))
}

export const resetVenueSpecificWidgetFormData = selectedTimeslot => (dispatch, getState) => {
  const state = getState()
  // pull data for currently selected venue or base venue
  let widgetSettings
  const { venueKey } = selectedTimeslot
  const { venueUrlKey } = state.venueInfo
  const { venueEntities } = state
  const timeslotVenue = venueKey ?? venueUrlKey
  if (timeslotVenue in venueEntities) {
    widgetSettings = venueEntities[timeslotVenue].widgetSettings
  } else {
    widgetSettings = state.widgetSettings
  }
  const formFields = resetFormData(widgetSettings, state.venueInfo)
  dispatch(resetFormFieldsData(formFields))
}

const cleanSearchAndUIData = (widgetSettings, venueInfo, app, isWaitlistMode, experienceId) => {
  // set up date and time based on venue's TZ
  const [year, month, day] = venueInfo.venueToday.split('-')
  const venueTodayMoment = moment()
    .tz(venueInfo.timezone)
    .year(year)
    .month(month - 1)
    .date(day) // eslint-disable-line
  // set up request params from URL
  const defaultDateNums = app.requestParams.defaultDate && _.map(app.requestParams.defaultDate.split('-'), val => Number(val, 10))
  let defaultDateMoment
  if (defaultDateNums && defaultDateNums.length === 3) {
    const [defaultYear, defaultMonth, defaultDay] = defaultDateNums
    defaultDateMoment = moment()
      .tz(venueInfo.timezone)
      .year(defaultYear)
      .month(defaultMonth - 1)
      .date(defaultDay) // eslint-disable-line
  }

  // settings type const
  const SETTINGS_TYPE = {
    hardDefault: 'hardDefault',
    softDefault: 'softDefault',
    noDefault: 'noDefault',
  }

  // use dates to figure out what search time and format to use
  const startDateMoment = defaultDateMoment && defaultDateMoment > venueTodayMoment ? defaultDateMoment : venueTodayMoment

  let startTimeMoment
  if (app.requestParams.defaultTime) {
    startTimeMoment = moment(app.requestParams.defaultTime, 'h:mA')
    if (!startTimeMoment.isValid()) {
      startTimeMoment = moment(app.requestParams.defaultTime, 'H:M')
    }
    if (!startTimeMoment.isValid()) {
      startTimeMoment = null
    }
  }

  // use starttimemoment to configure setting type
  let settingsType
  if (startTimeMoment) {
    settingsType = SETTINGS_TYPE.hardDefault
  } else if (widgetSettings.defaultSearchTime) {
    settingsType = SETTINGS_TYPE.softDefault
  } else {
    settingsType = SETTINGS_TYPE.noDefault
  }

  // set initial search moment
  const initialMoment = moment().year(startDateMoment.year()).month(startDateMoment.month()).date(startDateMoment.date())

  const adjustedMoment = initialMoment.clone()
  let hour
  let minute
  // set time display format
  const timeDisplayFormat = venueInfo.locale === 'en_GB' ? 'HH:mm' : 'h:mm a'

  // set default search time
  const defaultSearchTime = startTimeMoment || moment(widgetSettings.defaultSearchTime, 'h:mmA')
  const correctDefaultSearchTimeMoment = initialMoment.clone().hour(defaultSearchTime.hour()).minute(defaultSearchTime.minute())

  // if date time from the url, keep it as is
  // if date time is from widget settings, prefer it but adjust if real time is later then default
  // if there is no date time from url or widget settings, adjust dynamically
  if (settingsType === SETTINGS_TYPE.hardDefault) {
    hour = startTimeMoment.hour()
    minute = startTimeMoment.minute()
  } else if (settingsType === SETTINGS_TYPE.softDefault) {
    if (initialMoment.isAfter(correctDefaultSearchTimeMoment)) {
      hour = initialMoment.hour() + 1
      minute = 0

      correctDefaultSearchTimeMoment.hour(initialMoment.hour() + 1).minute(0)
    } else {
      hour = correctDefaultSearchTimeMoment.hour()
      minute = correctDefaultSearchTimeMoment.minute()
    }
  } else if (startDateMoment.hour() < 19) {
    hour = 19
    minute = 0
  } else if (startDateMoment.minute() < 30) {
    hour = startTimeMoment.hour()
    minute = 30
  } else {
    hour = startTimeMoment.hour() + 1
    minute = 0
  }

  adjustedMoment.hour(hour).minute(minute).second(0)

  const bookingEndMoment =
    !venueInfo.newResWidgetEnabled && widgetSettings.saleStartType === 'DAYS'
      ? venueTodayMoment.clone().add(widgetSettings.saleStartNum, 'days')
      : null

  // set party size configs
  const minPartySize = Math.max(1, Number(widgetSettings.minGuests, 10))
  const maxPartySize = Math.max(1, Number(widgetSettings.maxGuests, 10))
  let defaultPartySize = Number(app.requestParams.defaultPartySize, 10)
  if (defaultPartySize && (defaultPartySize < minPartySize || defaultPartySize > maxPartySize)) {
    defaultPartySize = null
  }
  const partySize = defaultPartySize || (minPartySize > 2 || maxPartySize < 2 ? minPartySize : 2)

  // set duration configs
  const enableDurationPicker = Boolean(app.requestParams.durationPicker) && app.requestParams.durationPicker !== 'false'
  const minDuration = Math.max(15, Number(widgetSettings.minimumSearchDuration, 10))
  const maxDuration = Math.max(15, Number(widgetSettings.maximumSearchDuration, 10))
  const durationInterval = Math.max(15, Number(widgetSettings.searchDurationInterval, 10))
  const defaultDurationSetting = Math.max(15, Number(widgetSettings.defaultSearchDuration, 10))
  let defaultDuration = Number(app.requestParams.defaultDuration, 10)
  if (defaultDuration && (defaultDuration < minDuration || defaultDuration > maxDuration)) {
    defaultDuration = null
  }
  const duration = defaultDuration || defaultDurationSetting || 15

  let selectedVenue
  const { venueMap } = venueInfo
  if (venueMap.get(venueInfo.urlKey)) {
    selectedVenue = venueInfo.urlKey
  } else if (venueMap.size > 2) {
    selectedVenue = ALL_LOCATIONS
  } else {
    selectedVenue = venueMap.keySeq().first()
  }

  const { saleStartDate } = widgetSettings
  const { saleEndDate } = widgetSettings
  const { lockTime } = widgetSettings
  const timeSlotInterval = 15

  // set custom timeslot data
  let customTimeSlotCurrentIndex = 0
  let customTimeSlotsByValue
  let firstSeatingMoment
  let lastSeatingMoment
  let defaultTimeMatched = false
  const hasCustomTimeSlots =
    widgetSettings.availabilityTimeRange === 'customTimeRange' || widgetSettings.availabilityTimeRange === 'specificTimeSlots'

  if (widgetSettings.availabilityTimeRange === 'specificTimeSlots' && widgetSettings.specificTimeSlots) {
    // this is expecting an ordered list of times
    // if we set this anywhere but widget settings, need to
    // do another sort of the values here or the time slots will be unordered as well
    customTimeSlotsByValue = widgetSettings.specificTimeSlots.map(slot => moment(slot, 'h:mmA').format(timeDisplayFormat))
  } else if (widgetSettings.availabilityTimeRange === 'customTimeRange' && widgetSettings.firstSeating && widgetSettings.lastSeating) {
    firstSeatingMoment = moment(widgetSettings.firstSeating, 'h:mmA')
    lastSeatingMoment = moment(widgetSettings.lastSeating, 'h:mmA')

    customTimeSlotsByValue = formattedTimesByMinuteDuration(
      timeSlotInterval,
      firstSeatingMoment.clone(),
      lastSeatingMoment.clone(),
      timeDisplayFormat
    )
  }

  if (hasCustomTimeSlots && ((widgetSettings.firstSeating && widgetSettings.lastSeating) || widgetSettings.specificTimeSlots)) {
    customTimeSlotCurrentIndex = customTimeSlotsByValue.indexOf(defaultSearchTime.format(timeDisplayFormat))

    if (customTimeSlotCurrentIndex < 0) {
      customTimeSlotCurrentIndex = 0
    } else {
      defaultTimeMatched = true
    }

    const selectedSlot = moment(customTimeSlotsByValue[customTimeSlotCurrentIndex], timeDisplayFormat)

    let selectedSlotCurrentMoment = initialMoment.clone().hour(selectedSlot.hour()).minute(selectedSlot.minute())
    let temp

    // choose the latest slot after current time
    if (
      (initialMoment.isAfter(selectedSlotCurrentMoment) && settingsType !== SETTINGS_TYPE.hardDefault) ||
      (settingsType === SETTINGS_TYPE.hardDefault && !defaultTimeMatched)
    ) {
      while (initialMoment.isAfter(selectedSlotCurrentMoment) && customTimeSlotCurrentIndex < customTimeSlotsByValue.length - 1) {
        customTimeSlotCurrentIndex += 1
        temp = moment(customTimeSlotsByValue[customTimeSlotCurrentIndex], timeDisplayFormat)
        selectedSlotCurrentMoment = initialMoment.clone().hour(temp.hour()).minute(temp.minute())
      }
    }

    adjustedMoment.hour(selectedSlotCurrentMoment.hour()).minute(selectedSlotCurrentMoment.minute())
  }

  const searchDateMoment = saleStartDate && saleEndDate ? moment(saleStartDate, 'MM/DD/YY') : initialMoment.clone()
  const maxDaysOut = Number(app.requestParams.maxDaysOut || widgetSettings.maxDaysOut)
  // initialize start date / calendar
  let calendarDateMoment
  if (defaultDateMoment) {
    calendarDateMoment =
      defaultDateMoment > venueTodayMoment
        ? defaultDateMoment.clone().date(1).hour(0).minute(0)
        : venueTodayMoment.clone().date(1).hour(0).minute(0)
  } else {
    calendarDateMoment = searchDateMoment.clone().tz(venueInfo.timezone).date(1).hour(0).minute(0)
  }

  // update ui state with calendar date moment
  const ui = Map({
    calendarDateMoment,
  })

  // initialize search state
  const search = Map({
    venueToday: venueTodayMoment,
    dateMoment: searchDateMoment,
    timeMoment: lockTime ? moment(lockTime, 'H:mm:ss') : adjustedMoment.clone(),
    customTimeSlots: customTimeSlotsByValue,
    customTimeSlotCurrentIndex,
    saleStartDate: saleStartDate ? moment(saleStartDate, 'MM/DD/YY') : null,
    lockTime: lockTime ? moment(lockTime, 'H:mm:ss') : null,
    saleEndDate: saleEndDate ? moment(saleEndDate, 'MM/DD/YY') : null,
    minPartySize,
    maxPartySize,
    partySize,
    enableDurationPicker,
    minDuration,
    maxDuration,
    durationInterval,
    duration,
    venueMap,
    selectedVenue,
    initialLocale: venueInfo.locale,
    bookingEnd: bookingEndMoment,
    disableDecrement: Map({
      dateMoment: initialMoment.isSame(venueTodayMoment, 'day'),
      timeMoment: !!lockTime || (hasCustomTimeSlots && customTimeSlotCurrentIndex === 0),
      partySize: partySize === minPartySize,
    }),
    disableIncrement: Map({
      timeMoment: !!lockTime || (hasCustomTimeSlots && customTimeSlotCurrentIndex === customTimeSlotsByValue.length - 1),
      partySize: partySize === maxPartySize,
    }),
    selectedTimeSlot: isWaitlistMode ? moment().add(2, 'days') : null,
    accessPersistentId: null,
    lastAvailableDate: null,
    lastQueriedDate: null,
    resultsWithinTimeHalo: experienceId
      ? 1440 // 24h
      : widgetSettings.searchHaloDurationHours * 60, // minutes
    targetTimeHalo: experienceId ? 1440 : 90, // minutes
    timeSlotInterval, // minutes
    pageTitle: '',
    maxDaysOut,
  })
  return { search, ui }
}

const cleanLanguageData = (venueInfo, venueEntities, app, selectedVenue, isWaitlistMode, isLandingPageMode) => {
  const venueLanguages = JSON.parse(venueInfo.venueLanguagesJson)
  const urlLanguage = app.requestParams.lang
  const urlLanguageValid = urlLanguage && _.find(venueLanguages, { value: urlLanguage, is_enabled: true })
  const baseVenueUrlKey = venueInfo.urlKey

  let venueLanguageStringMap = {}
  let currentlySelectedLanguageStrings = {}
  const getLanguageStringsFromJson = languageStringsJson => (languageStringsJson ? JSON.parse(languageStringsJson) : {})

  if (isWaitlistMode || isLandingPageMode) {
    currentlySelectedLanguageStrings = getLanguageStringsFromJson(venueInfo.languageStringsJson)
  } else {
    const masterStrings = getLanguageStringsFromJson(venueInfo.masterLanguageStringsJson)
    const getMergedLanguageStrings = (venueStrings, enabledLanguageCodes) =>
      Object.keys(masterStrings).reduce((strAcc, languageCode) => {
        if (enabledLanguageCodes.includes(languageCode)) {
          return {
            ...strAcc,
            ...{ [languageCode]: { ...masterStrings[languageCode], ...venueStrings[languageCode] } },
          }
        }
        return { ...strAcc }
      }, {})

    venueLanguageStringMap = Object.values(venueEntities).reduce((mapAcc, venueObj) => {
      const { enabledLanguageCodes, languageStringsJson } = venueObj
      const venueLanguageStrings = getLanguageStringsFromJson(languageStringsJson)
      return {
        ...mapAcc,
        ...{ [venueObj.urlKey]: getMergedLanguageStrings(venueLanguageStrings, enabledLanguageCodes) },
      }
    }, {})

    if (!(baseVenueUrlKey in venueLanguageStringMap)) {
      const { languageStringsJson, enabledLanguageCodes } = venueInfo
      const baseVenueLanguageStringOverrides = getLanguageStringsFromJson(languageStringsJson)
      venueLanguageStringMap[baseVenueUrlKey] = getMergedLanguageStrings(baseVenueLanguageStringOverrides, enabledLanguageCodes)
    }
    const currentVenue = baseVenueUrlKey !== selectedVenue && selectedVenue in venueLanguageStringMap ? selectedVenue : baseVenueUrlKey
    currentlySelectedLanguageStrings = venueLanguageStringMap[currentVenue]
  }

  const selectedLanguage = urlLanguageValid ? urlLanguage : venueInfo.selectedLanguage

  return {
    clientPreferredLanguage: Cookies.get(CLIENT_PREFERRED_LANGUAGE_COOKIE),
    venueLanguages,
    selectedLanguage,
    languageStrings: currentlySelectedLanguageStrings,
    selectedLanguageStrings: currentlySelectedLanguageStrings[selectedLanguage] || {},
    tagLanguageStrings: getLanguageStringsFromJson(venueInfo.tagLanguageStrings),
    hasLangParam: Boolean(urlLanguage),
    venueLanguageStringMap,
    baseVenueUrlKey,
  }
}

const cleanInitialFormData = (widgetSettings, venueInfo) => {
  // prefill form fields for checkout using the venue's widget settings and phone number data
  const venueCountryCode = venueInfo.municipality.countryCode.length === 2 ? venueInfo.municipality.countryCode.toLowerCase() : 'us'
  const venueDialCode = allCountries[iso2Lookup[venueCountryCode]].dialCode
  const agreedToReservationSmsOptIn =
    !!widgetSettings.displayReservationSmsOptIn && !!widgetSettings.reservationSmsOptInOn && !!venueInfo.reservationSmsEnabled

  return Map({
    formErrors: Map({}),
    firstName: '',
    lastName: '',
    email: '',
    consentToNotifications: agreedToReservationSmsOptIn,
    phoneNumber: '',
    phoneNumberLocale: venueCountryCode,
    dialCode: venueDialCode,
    birthday: '',
    postalCode: '',
    note: '',
    promoCode: '',
    cardFirstName: '',
    cardLastName: '',
    cardNum: '',
    cardMonthExp: '',
    cardYearExp: '',
    cardCvv: '',
    zipCode: '',
    isVerifyingPromoCode: false,
    validPromoCode: null,
    agreedToBookingPolicy: false,
    agreedCustomCheckoutPolicy: !!widgetSettings.isDefaultCustomCheckoutPolicyOn,
    displayBookingPolicy: false,
    agreedToWaitlistPolicy: false,
    ageToConsent: widgetSettings.ageToConsent,
    agreedToAboveAgeConsentOn: false,
    agreedToVenueGroupMarketingOptIn: !!widgetSettings.isVenueGroupMarketingPolicyDefaultOn,
    agreedToTailoredCommunicationOptIn: widgetSettings.isTailoredCommunicationPolicyDefaultOn && widgetSettings.tailoredCommunicationOn,
    agreedToVenueSpecificMarketingOptIn: !!widgetSettings.isVenueSpecificMarketingPolicyDefaultOn,
    agreedToVenueSmsMarketingOptIn: !!widgetSettings.isVenueSmsMarketingPolicyDefaultOn,
    agreedToDietaryGdprOptinOn: false,
    agreedToReservationSmsOptIn,
    showSpecialAttentionMessage: !!widgetSettings.showSpecialAttentionMessage,
    displayVenueGroupMarketingOptInPolicy: !!widgetSettings.venueGroupMarketingOn,
    displayTailoredCommunicationOptInPolicy: !!widgetSettings.tailoredCommunicationOn,
    displayVenueSpecificMarketingOptInPolicy: !!widgetSettings.venueSpecificMarketingOn,
    displayVenueSmsMarketingOptInPolicy: venueInfo.isSmsMarketingEnabled && !!widgetSettings.venueSmsMarketingOn,
    displayReservationSmsOptInPolicy: !!widgetSettings.displayReservationSmsOptIn,
    displayAboveAgeConsentPolicy: !!widgetSettings.aboveAgeConsentOn,
    isDisplayCustomCheckoutPolicy: !!widgetSettings.customCheckoutPolicyOn,
    displayAgreedToDietaryOptIn: !!widgetSettings.requireDietaryTagGdprOptIn,
    textVenueGroupMarketingOptIn: '',
    textVenueSpecificMarketingOptIn: '',
    textVenueSmsMarketingOptIn: '',
    textReservationSmsOptIn: '',
    textTailoredCommunicationOptInLabel: '',
    textTailoredCommunicationOptInHeader: '',
    textTailoredCommunicationOptInBody: '',
    bookingPolicyStatus: 'valid',
    customCheckoutPolicyStatus: 'valid',
    dietaryOptinStatus: 'valid',
    agePolicyStatus: 'valid',
    recaptcha: null,
    recaptchaOn: widgetSettings.recaptchaOn,
    completeReCaptcha: true,
    promoCodeInfo: null,
    hasClientToken: false,
    gratuityCharge: '',
    selectedGratuityCharge: '',
    preferredMessagingChannel: venueInfo.isWhatsappEnabled ? venueInfo.defaultMessageChannel : undefined,
  })
}

const resetFormData = (widgetSettings, venueInfo) => {
  // reset form fields for checkout using the venue's widget settings and phone number data
  // this will be used for the cross-sell/group widget once a timeslot is selected and we know which venue a guest is checking out for
  const agreedToReservationSmsOptIn =
    !!widgetSettings.displayReservationSmsOptIn && !!widgetSettings.reservationSmsOptInOn && !!venueInfo.reservationSmsEnabled

  return Map({
    consentToNotifications: agreedToReservationSmsOptIn,
    agreedCustomCheckoutPolicy: !!widgetSettings.isDefaultCustomCheckoutPolicyOn,
    ageToConsent: widgetSettings.ageToConsent,
    agreedToVenueGroupMarketingOptIn: !!widgetSettings.isVenueGroupMarketingPolicyDefaultOn,
    agreedToTailoredCommunicationOptIn: widgetSettings.isTailoredCommunicationPolicyDefaultOn && widgetSettings.tailoredCommunicationOn,
    agreedToVenueSpecificMarketingOptIn: !!widgetSettings.isVenueSpecificMarketingPolicyDefaultOn,
    agreedToVenueSmsMarketingOptIn: !!widgetSettings.isVenueSmsMarketingPolicyDefaultOn,
    agreedToReservationSmsOptIn,
    showSpecialAttentionMessage: !!widgetSettings.showSpecialAttentionMessage,
    displayVenueGroupMarketingOptInPolicy: !!widgetSettings.venueGroupMarketingOn,
    displayTailoredCommunicationOptInPolicy: !!widgetSettings.tailoredCommunicationOn,
    displayVenueSpecificMarketingOptInPolicy: !!widgetSettings.venueSpecificMarketingOn,
    displayVenueSmsMarketingOptInPolicy: venueInfo.isSmsMarketingEnabled && !!widgetSettings.venueSmsMarketingOn,
    displayReservationSmsOptInPolicy: !!widgetSettings.displayReservationSmsOptIn,
    displayAboveAgeConsentPolicy: !!widgetSettings.aboveAgeConsentOn,
    isDisplayCustomCheckoutPolicy: !!widgetSettings.customCheckoutPolicyOn,
    displayAgreedToDietaryOptIn: !!widgetSettings.requireDietaryTagGdprOptIn,
    recaptchaOn: widgetSettings.recaptchaOn,
  })
}

const cleanEntitiesData = tagGroupsRaw => {
  // set tag group data and post process
  const clientTagGroups = {}
  const reservationTagGroups = {}
  const tagGroups = _.mapValues(tagGroupsRaw, (tagGroup, tagGroupId) => {
    const formatTagGroup = camelCaseObject(_.omit(tagGroup, 'tag_name_displays'))
    formatTagGroup.tagNameDisplays = tagGroup.tag_name_displays
    formatTagGroup.tags = _.keyBy(formatTagGroup.tags, tagName => tagName)
    formatTagGroup.selectedTags = {}
    const camelCaseType = _.camelCase(formatTagGroup.type)
    if (formatTagGroup.domain === 'VenueGroupClient') {
      clientTagGroups[camelCaseType] = tagGroupId
    } else if (formatTagGroup.domain === 'ReservationActual') {
      reservationTagGroups[camelCaseType] = tagGroupId
    }
    return formatTagGroup
  })
  // set tags data
  const tags = Map().merge({
    tagGroups,
    clientTagGroups,
    reservationTagGroups,
  })
  return { tags }
}
