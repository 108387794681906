import _ from 'lodash'
import moment from 'moment-timezone'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings, selectCalculatedLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { viewTypes } from 'widget/dining/utils/constantTypes'
import Branding from '../../../../component-lib/Widget/Branding'
import { shortDayFormat } from '../../../scripts/utils/constants'
import styles from '../assets/styles/modifyRes'
import SearchResults from '../SearchResults/containers/SearchResultsView'
import Checkout from './Checkout'
import CheckoutWaitlist from './CheckoutWaitlist'
import Confirmation from './Confirmation'
import DiningUpsells from './DiningUpsells'
import Error from './Error'
import Experiences from './Experiences'
import LandingPage from './LandingPage'
import Login from './Login'
import ModalManager from './ModalManager'
import Request from './Request'
import Search from './Search'
import WaitingRoom from './WaitingRoom'
import { IntlProvider } from 'react-intl'

const ModifyResBanner = ({ date, guests, time, guestsLabel, modifyLabel }) => (
  <div style={styles.banner}>
    <div style={styles.bannerChild}>{modifyLabel}</div>
    <div style={styles.separator}>|</div>
    <div style={styles.bannerChild}>{date}</div>
    <div style={styles.separator}>&#9679;</div>
    <div style={styles.bannerChild}>{`${guests} ${guestsLabel}`}</div>
    <div style={styles.separator}>&#9679;</div>
    <div style={styles.bannerChild}>{time}</div>
  </div>
)

class ViewManager extends Component {
  constructor(props) {
    super(props)
    this.appElement = document.getElementById('dining-widget-app')

    this.viewMap = {
      SEARCH: Search,
      EXPERIENCES: Experiences,
      SEARCH_RESULTS: SearchResults,
      LOGIN: Login,
      CHECKOUT: Checkout,
      CONFIRMATION: Confirmation,
      REQUEST: Request,
      UPSELLS: DiningUpsells,
      ERROR: Error,
      CHOOSE_WAITLIST_EXPERIENCE: CheckoutWaitlist,
      CHECKOUT_WAITLIST: CheckoutWaitlist,
      WAITING_ROOM: WaitingRoom,
      WAITLIST_CLOSED: WaitingRoom,
      VENUE_CLOSED: WaitingRoom,
      LANDING_PAGE: LandingPage,
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
      {
        type: 'heightEvent',
        size: this.appElement.getBoundingClientRect().height,
      },
      '*'
    )
  }

  componentDidUpdate() {
    document.documentElement.scrollTop = 0
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
      {
        type: 'heightEvent',
        size: this.appElement.getBoundingClientRect().height,
      },
      '*'
    )
  }

  render() {
    const {
      isModifyResMode,
      reservationToBeModified,
      currentView,
      isWaitlistWidget,
      isWidgetWhitelabeled,
      colorCellBackground,
      fontsColorPrimary,
      fontFamily,
      textGuestLabel,
      textPoweredBy,
      textWidgetModifyLabel,
      locale,
      selectedLanguage,
    } = this.props
    const View = this.viewMap[currentView]
    const brandFontSize = '11px'

    const shouldTranslateDates = !_.isNil(selectedLanguage)
    const dateFormat = shortDayFormat[locale] || shortDayFormat.default
    let date_formatted_short = null

    if (isModifyResMode && reservationToBeModified && currentView !== viewTypes.CONFIRMATION) {
      if (shouldTranslateDates) {
        date_formatted_short = moment(reservationToBeModified.get('date')).clone().locale(selectedLanguage).format(dateFormat)
      } else {
        date_formatted_short = reservationToBeModified.get('date_format_mostly_full_no_year')
      }
    }

    return (
      <IntlProvider locale={selectedLanguage.replace('_', '-')}>
        <div>
          <ModalManager />
          {isModifyResMode && reservationToBeModified && currentView !== viewTypes.CONFIRMATION && (
            <ModifyResBanner
              date={date_formatted_short}
              time={reservationToBeModified.get('arrival_time_display')}
              guests={reservationToBeModified.get('total_guests')}
              guestsLabel={textGuestLabel}
              modifyLabel={textWidgetModifyLabel}
            />
          )}
          <View />
          {currentView !== viewTypes.CONFIRMATION && !isWaitlistWidget && (
            <Branding
              cellColor={colorCellBackground}
              fontColor={fontsColorPrimary}
              fontFamily={fontFamily}
              fontSize={brandFontSize}
              isWhitelabeled={isWidgetWhitelabeled}
              poweredByLabel={textPoweredBy}
            />
          )}
        </div>
      </IntlProvider>
    )
  }
}

const mapStateToProps = state => {
  const stage = state.ui.get('stage')
  const viewSequence = state.ui.get('viewSequence').toJS()
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)

  return {
    currentView: viewSequence[stage],
    isWidgetWhitelabeled: state.widgetSettings.isWidgetWhitelabeled,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontFamily: state.widgetSettings.font,
    isModifyResMode: state.modifyReservation.get('isModifyResMode'),
    reservationToBeModified: state.modifyReservation.get('actual'),
    isWaitlistWidget: state.app.isWaitlistWidget,
    isLandingPageWidget: state.app.isLandingPageWidget,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textPoweredBy: languageStrings.textPoweredBy,
    textWidgetModifyLabel: languageStrings.textWidgetModifyLabel,
    locale: state.venueInfo.locale,
    selectedLanguage: state.languages.selectedLanguage,
  }
}

export default connect(mapStateToProps, null)(Radium(ViewManager))
