/* globals widgetInit */
import { Component } from 'react'
import { connect } from 'react-redux'
import { tryGetWidgetData } from './actions/loadWidgetData'
import { loadingBackground, loadingSpinner } from './assets/styles/loader'
import App from './containers/App'

class WidgetLoadingWrapperComponent extends Component {
  componentDidMount() {
    this.props.tryGetWidgetData()
  }

  render() {
    if (this.props.isFetchingWidgetData) {
      const spinnerUrl = `${this.props.mediaUrl}images/SevenRoomsLoader.svg`
      return (
        <div style={loadingBackground}>
          {this.props.errorMessage ? (
            <div>{this.props.errorMessage}</div>
          ) : (
            <img alt="sevenrooms-spinner" src={spinnerUrl} style={loadingSpinner} />
          )}
        </div>
      )
    }
    return <App />
  }
}

const mapStateToProps = state => ({
  mediaUrl: state.preloadedState.get('mediaUrl'),
  isFetchingWidgetData: state.preloadedState.get('isFetchingWidgetData'),
  errorMessage: state.preloadedState.get('errorMessage'),
})

const mapDispatchToProps = {
  tryGetWidgetData,
}

export const WidgetLoadingWrapper = connect(mapStateToProps, mapDispatchToProps)(WidgetLoadingWrapperComponent)
