import { defineMessages } from '@sevenrooms/core/locales'

export const intlWidgetMessages = defineMessages({
  messageChannelOptionWhatsApp: {
    id: 'messageChannelOptionWhatsApp',
    defaultMessage: 'WhatsApp',
  },
  messageChannelOptionSMS: {
    id: 'messageChannelOptionSMS',
    defaultMessage: 'SMS Text Message',
  },
})
