import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddressInput from 'svr/component-lib/Generic/TextInputs/AddressInput'
import { handleNetworkPayment, isNetworkRedux } from 'svr/lib/Payments/NetworkRedux'
import { postCheckoutFailure } from 'widget/dining/actions/services'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { validateNotEmpty, validateCardMonthExp, validateCardYearExp, validateCardCvv } from 'svr/common/Validators'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import CardNumberInput from '../../../../lib/TextInputs/CardNumberInput'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { changeFormField } from '../actions/forms'
import { toggleModalDisplay } from '../actions/navigation'
import styles from '../assets/styles/checkout'
import { selectTimeSlotEntity } from '../utils/selectors'

class CheckoutPayment extends Component {
  constructor(props) {
    super(props)

    this.state = { address: null }
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'cardFirstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'cardLastName')
    this.handleCardNumChange = this.props.changeFormField.bind(this, 'cardNum')
    this.handleMonthExpChange = this.props.changeFormField.bind(this, 'cardMonthExp')
    this.handleYearExpChange = this.props.changeFormField.bind(this, 'cardYearExp')
    this.handleCvvChange = this.props.changeFormField.bind(this, 'cardCvv')
    this.handleZipCodeChange = this.props.changeFormField.bind(this, 'zipCode')
  }

  handleAddressChange = (address, place) => {
    const address1 = []
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.setState({ address: '' })
      this.props.changeFormField('address1', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })
    this.setState({
      address,
    })
    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('locality', locality)
    this.props.changeFormField('administrativeArea', administrativeArea)
    this.props.changeFormField('countryCode', countryCode)
    this.props.changeFormField('postalCode', postalCode)
  }

  componentDidMount() {
    const { baseUrl, mediaUrl, paymentGatewayApiKey, paymentAccountId, venueInfo } = this.props

    if (isNetworkRedux(venueInfo)) {
      window.NI.mountCardInput('ccFormMountId', {
        style: {
          main: {
            background: 'rgba(255, 255, 255, 0.5)',
            margin: 0,
            borderRadius: '8px',
          },
          base: {
            borderStyle: 'solid',
            borderWidth: '0 0 1px 0',
            borderColor: '#eee',
            fontFamily: 'Roboto',
            fontUrl: `${baseUrl}${mediaUrl}css/fonts.css`,
            fontSize: '14px',
          },
          input: {
            color: '#000',
            margin: 0,
            padding: '10px 10px 10px 17px',
          },
        },
        // API Key for WEB SDK from the portal; the
        // fail string is needed to prod the SDK into
        // triggering its own onFail method. smh.
        apiKey: paymentGatewayApiKey || 'fail',

        // outlet reference from the portal
        outletRef: paymentAccountId,

        // These apply to api validation
        onSuccess: () => {},
        onFail: () => {},

        onChangeValidStatus: () => {},
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.networkReduxResponse) && !_.isEmpty(nextProps.networkReduxResponse)) {
      this.props.handleNetworkPayment(nextProps.networkReduxResponse, postCheckoutFailure)
    }

    const { venueInfo } = this.props
    if (isNetworkRedux(venueInfo) && _.isEmpty(this.props.paymentPendingResponse) && !_.isEmpty(nextProps.paymentPendingResponse)) {
      this.props.handleNetworkPayment(nextProps.paymentPendingResponse, postCheckoutFailure)
    }
  }

  render() {
    const {
      cardFirstName,
      cardLastName,
      cardNum,
      cardMonthExp,
      cardYearExp,
      cardCvv,
      zipCode,
      cost,
      fontsColorPrimary,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      paymentFields,
      paymentSystem,
      topElementId,
      formErrors,
      venueInfo,
      fontFamily,
      textPayment,
      textCardRequired,
      textFirstName,
      textLastName,
      textCreditCardNumber,
      textExpMonth,
      textExpYear,
      textCreditCardExpYearDefaultLabel,
      textCvv,
      textZipCode,
      mediaUrl,
      enablePromoCodes,
      promoCodes,
    } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }
    const boxStyle = venueInfo.needsZipCode ? styles.smallFormFourBox : styles.smallFormThreeBox
    const isValidAddress = !this.props.submitClicked || this.state.address
    const yearPlaceholder = [AccountTypes.CYBERSOURCE, AccountTypes.CYBERSOURCE_REDUX, AccountTypes.CYBERSOURCE_3DS_REDUX].includes(
      paymentSystem
    )
      ? textExpYear
      : textCreditCardExpYearDefaultLabel

    return (
      <div id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{cost ? textPayment : textCardRequired}</span>
        </div>
        <div style={styles.infoForm}>
          {!isNetworkRedux(venueInfo) ? (
            <div>
              <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={styles.formBox}>
                  <TextInput
                    inputId="sr-cc-first-name"
                    placeholder={`${textFirstName} *`}
                    limitType="name"
                    value={cardFirstName}
                    charLimit={50}
                    validator={validateNotEmpty}
                    isValid={!formErrors.cardFirstName}
                    onChange={this.handleFirstNameChange}
                    style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
                    ariaLabel="Credit Card First Name"
                    textColor={this.props.fontsColorPrimary}
                    ref={input => {
                      paymentFields.cardFirstName = input
                    }}
                    {...textInputProps}
                    data-test="sr-cc-first-name"
                  />
                </div>
                <div style={styles.formBox}>
                  <TextInput
                    inputId="sr-cc-last-name"
                    placeholder={`${textLastName} *`}
                    limitType="name"
                    value={cardLastName}
                    charLimit={50}
                    validator={validateNotEmpty}
                    isValid={!formErrors.cardLastName}
                    onChange={this.handleLastNameChange}
                    style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
                    textColor={this.props.fontsColorPrimary}
                    ariaLabel="Credit Card Last Name"
                    ref={input => {
                      paymentFields.cardLastName = input
                    }}
                    {...textInputProps}
                    data-test="sr-cc-last-name"
                  />
                </div>
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          ) : null}
          {isNetworkRedux(venueInfo) ? (
            <div>
              <div id="ccFormMountId" style={{ height: '210px' }} />
              <div id="cc3dsMountId">
                <div id="loadingIndicator" />
              </div>
            </div>
          ) : null}
          {![AccountTypes.STRIPE, AccountTypes.NETWORK_REDUX, AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE].includes(paymentSystem) ? (
            <div>
              <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={styles.formBox}>
                  <CardNumberInput
                    placeholder={`${textCreditCardNumber} *`}
                    mediaUrl={mediaUrl}
                    enablePromoCodes={enablePromoCodes}
                    promoCodes={promoCodes}
                    setPromoCode={this.props.setPromoCode}
                    removePromoCode={this.props.removePromoCode}
                    triggeredValidPromoCode={this.props.promoCodeInfo}
                    value={cardNum}
                    isValid={!formErrors.cardNum}
                    onChange={this.handleCardNumChange}
                    style={styles.singleFieldInput}
                    textColor={this.props.fontsColorPrimary}
                    ref={input => {
                      paymentFields.cardNum = input
                    }}
                    testId="sr-cc-number"
                    {...textInputProps}
                  />
                </div>
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div style={[styles.formLine, styles.bottomBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={[styles.formBox, boxStyle]}>
                  <TextInput
                    placeholder={`${textExpMonth} *`}
                    limitType="number"
                    charLimit={2}
                    value={cardMonthExp}
                    validator={validateCardMonthExp}
                    isValid={!formErrors.cardMonthExp}
                    onChange={this.handleMonthExpChange}
                    style={styles.thirdBox}
                    textColor={this.props.fontsColorPrimary}
                    ariaLabel="Credit Card Month Expiration"
                    ref={input => {
                      paymentFields.cardMonthExp = input
                    }}
                    {...textInputProps}
                    data-test="sr-cc-month-expiry"
                    inputId="sr-mm-*"
                  />
                </div>
                <div style={[styles.formBox, boxStyle]}>
                  <TextInput
                    placeholder={`${yearPlaceholder} *`}
                    limitType="number"
                    charLimit={4}
                    value={cardYearExp}
                    textColor={this.props.fontsColorPrimary}
                    validator={validateCardYearExp}
                    isValid={!formErrors.cardYearExp}
                    onChange={this.handleYearExpChange}
                    style={styles.thirdBox}
                    ariaLabel="Credit Card Year Expiration"
                    ref={input => {
                      paymentFields.cardYearExp = input
                    }}
                    {...textInputProps}
                    data-test="sr-cc-year-expiry"
                    inputId="sr-yyyy-*"
                  />
                </div>
                <div style={[styles.formBox, boxStyle]}>
                  <TextInput
                    placeholder={`${textCvv} *`}
                    limitType="number"
                    charLimit={4}
                    value={cardCvv}
                    validator={validateCardCvv}
                    isValid={!formErrors.cardCvv}
                    onChange={this.handleCvvChange}
                    style={styles.thirdBox}
                    ariaLabel="Credit Card CVV"
                    textColor={this.props.fontsColorPrimary}
                    ref={input => {
                      paymentFields.cardCvv = input
                    }}
                    {...textInputProps}
                    data-test="sr-cc-cvc"
                    inputId="sr-cvv-*"
                  />
                </div>
                {venueInfo.needsZipCode ? (
                  <div style={styles.formBox}>
                    <TextInput
                      placeholder={`${textZipCode} *`}
                      charLimit={7}
                      value={zipCode}
                      validator={validateNotEmpty}
                      isValid={!formErrors.zipCode}
                      onChange={this.handleZipCodeChange}
                      style={styles.thirdBox}
                      textColor={this.props.fontsColorPrimary}
                      ariaLabel="Credit Card Zip Code"
                      ref={input => {
                        paymentFields.zipCode = input
                      }}
                      {...textInputProps}
                      inputId="sr-zipcode-*"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {this.props.isCybersourceBillingAddressRequired ? (
            <>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div style={[styles.formLine, styles.bottomBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
                <AddressInput
                  googlePlacesApiKey={widgetInit.googlePlacesApiKey}
                  placeholder="Enter your billing address"
                  showLabel={false}
                  value={this.state.address}
                  isValid={isValidAddress}
                  onChange={this.handleAddressChange}
                  testId="sr-input-address"
                  inputStyles={{ marginLeft: '20px', background: 'transparent' }}
                  backgroundColor={{ colorCheckoutCellBackground }}
                  placeholderColor={{ fontsColorCheckoutInactive }}
                  errorColor="#d01a21"
                  hideBorder={isValidAddress}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { formFields, widgetSettings, venueEntities, searchResults } = state
  const selectedVenue = venueEntities[searchResults.get('timeSlotVenue')]
  const languageStrings = selectLanguageStrings(state)
  return {
    cardFirstName: formFields.get('cardFirstName'),
    cardLastName: formFields.get('cardLastName'),
    cardNum: formFields.get('cardNum'),
    cardMonthExp: formFields.get('cardMonthExp'),
    cardYearExp: formFields.get('cardYearExp'),
    cardCvv: formFields.get('cardCvv'),
    zipCode: formFields.get('zipCode'),
    cost: selectTimeSlotEntity(state).cost,
    formErrors: formFields.get('formErrors').toJS(),
    venueInfo: state.venueInfo,
    fontFamily: widgetSettings.font,
    fontsColorPrimary: widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: widgetSettings.fontsColorCheckoutActive,
    colorError: widgetSettings.colorError,
    colorCheckoutCellBackground: widgetSettings.colorCheckoutCellBackground,
    colorLines: widgetSettings.colorLines,
    baseUrl: state.widgetSettings.baseUrl,
    mediaUrl: widgetSettings.mediaUrl,
    enablePromoCodes: widgetSettings.enablePromoCodes,
    promoCodes: searchResults.get('applicablePromoCodes'),
    paymentSystem: selectedVenue.paymentSystem,
    paymentAccountId: selectedVenue.paymentAccountId,
    paymentGatewayApiKey: selectedVenue.paymentGatewayApiKey,
    networkReduxResponse: state.payment.networkReduxResponse,
    paymentPendingResponse: state.payment.paymentPendingResponse,
    // text
    textPayment: languageStrings.textPayment,
    textCardRequired: languageStrings.textCardRequired,
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textCreditCardNumber: languageStrings.textCreditCardNumber,
    textExpMonth: languageStrings.textExpMonth,
    textExpYear: languageStrings.textExpYear,
    textCreditCardExpYearDefaultLabel: languageStrings.textCreditCardExpYearDefaultLabel,
    textCvv: languageStrings.textCvv,
    textZipCode: languageStrings.textZipCode,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  handleNetworkPayment: (response, postCheckoutFailure) => {
    dispatch(handleNetworkPayment(response, postCheckoutFailure))
  },
})

CheckoutPayment = connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPayment))

export default CheckoutPayment
