/* eslint react/prop-types: 0, no-shadow: 0  */
import _ from 'lodash'
import moment from 'moment-timezone'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings, selectCalculatedLanguageStrings, selectLanguageDateFields } from 'widget/dining/selectors/languageSelectors'
import { shortDayFormat, timeFormat } from '../../../scripts/utils/constants'
import { validateAllFields, changePhoneNum, changeFormField, changeFlag, clearFormValidation } from '../actions/forms'
import { revertStage, toggleModalDisplay, toggleSearchComponent, selectRequestTime, selectRequestEndTime } from '../actions/navigation'
import { submitRequest } from '../actions/services'
import styles from '../assets/styles/checkout'
import Banner from '../components/Banner'
import { modalTypes, searchTypes } from '../utils/constantTypes'
import { getBannerContents } from '../utils/selectors'
import RequestForm from './RequestForm'
import RequestPriorityAlertForm from './RequestPriorityAlertForm'
import { FormattedMessage, IntlProvider } from 'react-intl'

class Request extends Component {
  constructor(props) {
    super(props)
    this.inputFields = {}
    this.toggleReservationNotesModal = this.props.toggleModalDisplay.bind(this, modalTypes.RESERVATION_NOTES)
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'firstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'lastName')
    this.handleEmailChange = this.props.changeFormField.bind(this, 'email')
    this.handleConsentToNotifications = this.props.changeFormField.bind(this, 'consentToNotifications')
    this.handlePreferredMessagingChannelChange = function (evt) {
      this.props.changeFormField('preferredMessagingChannel', evt.target.value)
    }.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.toggleTimeSelect = this.props.toggleSearchComponent.bind(this, searchTypes.REQUEST_TIME)
    this.handleTimeRowClick = this.handleTimeRowClick.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.toggleEndTimeSelect = this.props.toggleSearchComponent.bind(this, searchTypes.REQUEST_END_TIME)
    this.handleEndTimeRowClick = this.handleEndTimeRowClick.bind(this)
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this)

    this.requestTimes = _.uniq(_.map(this.props.orderedRequests, timeIso => moment(timeIso).format(this.props.timeDisplayFormat)))
  }

  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
  }

  handleTimeRowClick() {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativeTimeSelect.focus()
    } else {
      this.toggleTimeSelect()
    }
  }

  handleEndTimeRowClick() {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativeEndTimeSelect.focus()
    } else {
      this.toggleEndTimeSelect()
    }
  }

  handleClearTimeValidation() {
    this.props.clearFormValidation('startTime')
    this.props.clearFormValidation('endTime')
  }

  handleTimeChange(e) {
    let timeMoment
    if (this.props.isMobile && !this.props.isAndroid) {
      timeMoment = moment(e.target.value, timeFormat.default)
    } else {
      timeMoment = moment(e, timeFormat.default)
    }
    this.handleClearTimeValidation()
    this.props.selectRequestTime(timeMoment)
  }

  handleEndTimeChange(e) {
    let timeMoment
    if (this.props.isMobile && !this.props.isAndroid) {
      timeMoment = moment(e.target.value, timeFormat.default)
    } else {
      timeMoment = moment(e, timeFormat.default)
    }
    this.handleClearTimeValidation()
    this.props.selectRequestEndTime(timeMoment)
  }

  handleSubmitClick() {
    const formErrors = _.reduce(
      this.inputFields,
      (errorMap, input, field) => _.assign(errorMap, !input.validate() && { [field]: true }),
      {}
    )

    if (this.props.birthday === '') {
      delete formErrors.birthday
    }

    this.props.validateAllFields(formErrors)

    if (_.isEmpty(formErrors)) {
      this.props.submitRequest()
    }
  }

  render() {
    const {
      bannerInfo,
      colorError,
      colorLines,
      colorPrimary,
      colorSummaryBar,
      colorWidgetBackground,
      fontFamily,
      fontsColorButton,
      fontsColorCheckoutActive,
      fontsColorCheckoutInactive,
      fontsColorPrimary,
      fontsColorSummaryBar,
      isAndroid,
      isMobile,
      resWidgetPriorityAlertsDetailsHeader,
      resWidgetPriorityAlertsReservationTimeBetween,
      resWidgetPriorityAlertsYourDetails,
      resWidgetPriorityAlertsSetAlertButton,
      revertStage,
      startOfDayTime,
      startTime,
      endTime,
      textHoldExpired,
      textHoldingTable,
      textSubmitRequest,
      priorityAlertsPartySizeMax,
      partySize,
      consentToNotifications,
      policySmsOptIn,
      policyUsTwilioSmsOptInSubfooter,
      priorityAlertsEnabled,
      priorityAlertsPartySizeMin,
      priorityAlertsSmsEnabled,
      displayReservationSmsOptIn,
      isExperienceMode,
      isWhatsappEnabled,
      preferredMessagingChannel,
    } = this.props

    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    const isPriorityAlert =
      !isExperienceMode && priorityAlertsEnabled && partySize <= priorityAlertsPartySizeMax && partySize >= priorityAlertsPartySizeMin

    return (
      <div style={[styles.checkoutWrapper, { backgroundColor: colorWidgetBackground }]}>
        <Banner
          info={bannerInfo}
          onBackClick={revertStage}
          colorSummaryBar={colorSummaryBar}
          fontsColorSummaryBar={fontsColorSummaryBar}
          colorLines={colorLines}
          textHoldingTable={textHoldingTable}
          textHoldExpired={textHoldExpired}
          isExperienceMode={isExperienceMode}
        />

        {isPriorityAlert ? (
          <RequestPriorityAlertForm
            {...this.props}
            inputFields={this.inputFields}
            onEmailChange={this.handleEmailChange}
            onFirstNameChange={this.handleFirstNameChange}
            onLastNameChange={this.handleLastNameChange}
            onTimeChange={this.handleTimeChange}
            onTimeRowClick={this.handleTimeRowClick}
            onToggleReservationNotesModal={this.toggleReservationNotesModal}
            onToggleTimeSelect={this.toggleTimeSelect}
            requestTimes={this.requestTimes}
            styles={styles}
            textInputProps={textInputProps}
            resWidgetPriorityAlertsDetailsHeader={resWidgetPriorityAlertsDetailsHeader}
            startOfDayTime={startOfDayTime}
            resWidgetPriorityAlertsReservationTimeBetween={resWidgetPriorityAlertsReservationTimeBetween}
            policySmsOptIn={policySmsOptIn}
            onEndTimeRowClick={this.handleEndTimeRowClick}
            onEndTimeChange={this.handleEndTimeChange}
            onToggleEndTimeSelect={this.toggleEndTimeSelect}
            resWidgetPriorityAlertsYourDetails={resWidgetPriorityAlertsYourDetails}
            consentToNotifications={consentToNotifications}
            onConsentToNotifications={this.handleConsentToNotifications}
            priorityAlertsSmsEnabled={priorityAlertsSmsEnabled && displayReservationSmsOptIn}
            isWhatsappEnabled={isWhatsappEnabled}
            preferredMessagingChannel={preferredMessagingChannel}
            handlePreferredMessagingChannelChange={this.handlePreferredMessagingChannelChange}
          />
        ) : (
          <RequestForm
            {...this.props}
            inputFields={this.inputFields}
            onEmailChange={this.handleEmailChange}
            onFirstNameChange={this.handleFirstNameChange}
            onLastNameChange={this.handleLastNameChange}
            onTimeChange={this.handleTimeChange}
            onTimeRowClick={this.handleTimeRowClick}
            onToggleReservationNotesModal={this.toggleReservationNotesModal}
            onToggleTimeSelect={this.toggleTimeSelect}
            requestTimes={this.requestTimes}
            styles={styles}
            textInputProps={textInputProps}
          />
        )}
        {isPriorityAlert && consentToNotifications && (
          <div style={[styles.footerText, { color: fontsColorPrimary, marginTop: '14px' }]}>
            <IntlProvider locale="en">
              <FormattedMessage
                id="policyUsTwilioSmsOptInSubfooter"
                // eslint-disable-next-line formatjs/enforce-default-message
                defaultMessage={policyUsTwilioSmsOptInSubfooter}
                values={{
                  i: chunks => <i>{chunks}</i>,
                }}
              />
            </IntlProvider>
          </div>
        )}
        <div style={[styles.buttonWrapper, styles.requestButtonMargin]}>
          <button
            type="submit"
            key="sr-submit-request-button"
            style={[styles.submitButton, { backgroundColor: colorPrimary, color: fontsColorButton }]}
            onClick={this.handleSubmitClick}
          >
            {isPriorityAlert ? resWidgetPriorityAlertsSetAlertButton : textSubmitRequest}
          </button>
        </div>
        {isMobile && !isAndroid && this.requestTimes.length > 1 && (
          <div>
            <select
              style={styles.hiddenInput}
              onChange={this.handleTimeChange}
              value={startTime}
              ref={input => {
                this.nativeTimeSelect = input
              }}
            >
              {_.map(this.requestTimes, (val, idx) => (
                <option key={`sr-request-time-${idx + 1}`} value={val}>
                  {val}
                </option>
              ))}
            </select>
            <select
              style={styles.hiddenInput}
              onChange={this.handleEndTimeChange}
              value={endTime}
              ref={input => {
                this.nativeEndTimeSelect = input
              }}
            >
              {_.map(this.requestTimes, (val, idx) => (
                <option key={`sr-request-end-time-${idx + 1}`} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  const { dateMoment } = selectLanguageDateFields(state)

  const {
    locale,
    startOfDayTime,
    priorityAlertsPartySizeMax,
    priorityAlertsPartySizeMin,
    priorityAlertsEnabled,
    priorityAlertsSmsEnabled,
  } = state.venueInfo
  const dateFormat = shortDayFormat[locale] || shortDayFormat.default
  const timeDisplayFormat = timeFormat[locale] || timeFormat.default
  const { formFields } = state

  return {
    partySize: state.search.get('partySize'),
    consentToNotifications: formFields.get('consentToNotifications'),
    bannerInfo: getBannerContents(state),
    queryDate: dateMoment.format(dateFormat),
    dateMoment: state.search.get('dateMoment'),
    isExperienceMode: state.experience.get('isExperienceMode'),
    timeDisplayFormat,
    startTime: moment(state.searchResults.get('selectedRequest')).format(timeDisplayFormat),
    endTime: moment(state.searchResults.get('selectedEndTimeRequest')).format(timeDisplayFormat),
    mediaUrl: state.widgetSettings.mediaUrl,
    userStatus: state.user.get('status'),
    firstName: state.formFields.get('firstName'),
    lastName: state.formFields.get('lastName'),
    email: state.formFields.get('email'),
    phoneNumber: state.formFields.get('phoneNumber'),
    orderedRequests: state.searchResults.get('orderedRequests').toJS(),
    phoneNumberLocale: state.formFields.get('phoneNumberLocale'),
    dialCode: state.formFields.get('dialCode'),
    note: state.formFields.get('note'),
    formErrors: state.formFields.get('formErrors').toJS(),
    isMobile: state.app.isMobile,
    isAndroid: state.app.isAndroid,
    displaySearchType: state.ui.get('displaySearchType'),
    startOfDayTime,
    priorityAlertsPartySizeMin,
    priorityAlertsPartySizeMax,
    priorityAlertsEnabled,
    priorityAlertsSmsEnabled,
    displayReservationSmsOptIn: state.widgetSettings.displayReservationSmsOptIn,

    // colors
    colorError: state.widgetSettings.colorError,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    colorLines: state.widgetSettings.colorLines,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
    fontFamily: state.widgetSettings.font,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    colorButton: state.widgetSettings.colorButton,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    colorHover: state.widgetSettings.colorHover,
    // text
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textEmailAddress: languageStrings.textEmailAddress,
    textPhoneNumber: languageStrings.textPhoneNumber,
    textSelectTimeDropdown: languageStrings.textSelectTimeDropdown,
    textSelectDurationDropdown: languageStrings.textSelectDurationDropdown,
    textRequestDetails: languageStrings.textRequestDetails,
    textStartTime: languageStrings.textStartTime,
    textSubmitRequest: languageStrings.textSubmitRequest,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textHoldingTable: languageStrings.textHoldingTable,
    textHoldExpired: languageStrings.textHoldExpired,
    textReservationNotes: languageStrings.textReservationNotes,

    resWidgetPriorityAlertsDetailsHeader: languageStrings.resWidgetPriorityAlertsDetailsHeader,
    resWidgetPriorityAlertsHeader: languageStrings.resWidgetPriorityAlertsHeader,
    resWidgetPriorityAlertsYourDetails: languageStrings.resWidgetPriorityAlertsYourDetails,
    resWidgetPriorityAlertsSetAlertButton: languageStrings.resWidgetPriorityAlertsSetAlertButton,
    resWidgetPriorityAlertsReservationTimeBetween: languageStrings.resWidgetPriorityAlertsReservationTimeBetween,
    policySmsOptIn: calcLanguageStrings.textReservationSmsOptIn,
    policyUsTwilioSmsOptInSubfooter: languageStrings.policyUsTwilioSmsOptInSubfooter,
    isWhatsappEnabled: state.venueInfo.isWhatsappEnabled,
    preferredMessagingChannel: state.venueInfo.isWhatsappEnabled ? state.formFields.get('preferredMessagingChannel', state.venueInfo.defaultMessageChannel) : null,

    experienceName: state.experience.get('isExperienceMode') ? state.experience.get('experience').get('name') : null,
  }
}

const mapDispatchToProps = dispatch => ({
  revertStage: toStage => {
    dispatch(revertStage(toStage))
  },
  validateAllFields: formErrors => {
    dispatch(validateAllFields(formErrors))
  },
  changePhoneNum: (changeTo, dialCode, formattedNumber) => {
    dispatch(changePhoneNum(changeTo, dialCode, formattedNumber))
  },
  changeFlag: (changeTo, selectedCountry) => {
    dispatch(changeFlag(changeTo, selectedCountry))
  },
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  submitRequest: () => {
    dispatch(submitRequest())
  },
  toggleSearchComponent: item => {
    dispatch(toggleSearchComponent(item))
  },
  selectRequestTime: requestTime => {
    dispatch(selectRequestTime(requestTime))
  },
  selectRequestEndTime: requestEndTime => {
    dispatch(selectRequestEndTime(requestEndTime))
  },
  clearFormValidation: field => {
    dispatch(clearFormValidation(field))
  },
})

const mergeProps = (stateProps, dispatchProps) => {
  const revertStage =
    stateProps.userStatus === 'guest' ? dispatchProps.revertStage.bind(this, false, 2) : dispatchProps.revertStage.bind(this, false, 1)

  const selectRequestTime = requestTime => {
    let requestMoment = moment(requestTime, timeFormat.default)
    requestMoment = stateProps.dateMoment.clone().hour(requestTime.hour()).minute(requestTime.minute())
    dispatchProps.selectRequestTime(requestMoment.format())
  }
  const selectRequestEndTime = requestEndTime => {
    let requestMoment = moment(requestEndTime, timeFormat.default)
    requestMoment = stateProps.dateMoment.clone().hour(requestEndTime.hour()).minute(requestEndTime.minute())
    dispatchProps.selectRequestEndTime(requestMoment.format())
  }
  return _.assign(stateProps, dispatchProps, { revertStage, selectRequestTime, selectRequestEndTime })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Radium(Request))
