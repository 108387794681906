import _ from 'lodash'
import Radium from 'radium'
import styled from 'styled-components'
import TextAreaInput from 'svr/lib/TextInputs/TextAreaInput'
import TextInput from 'svr/lib/TextInputs/TextInput'
import { validateNotEmpty, validateEmail } from '../../../../common/Validators'
import PhoneNumber from '../../../../component-lib/Widget/PhoneNumber'
import styles from '../assets/styles/checkout'
import { modalTypes } from '../utils/constantTypes'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { intlWidgetMessages } from '../../../common/intlMessages'

const is7RGdprPolicyRegex = /^https?:\/\/[\w\d._-]*sevenrooms[\w\d._-]*.com(:\d+)?\/gdpr-policy\//i

const FormContainer = styled.div`
  padding: 20px 40px 40px 40px;

  @media (max-width: 500px) {
    padding: 10px 3% 3% 3%;
  }
`

const ariaLabelBlankFeedback = (text, errored, blankErrorText) => (errored === false ? `${text} ${blankErrorText}` : text)

const ariaLabelFormatFeedback = (text, errored, value, invalidErrorText, blankErrorText) => {
  if (errored === false) {
    if (value === '') {
      return `${text} ${blankErrorText}`
    }
    return `${text} ${invalidErrorText}`
  }
  return text
}

const WaitlistForm = ({
  fontsColorLinks,
  fontsColorPrimary,
  fontsColorCheckoutInactive,
  fontsColorCheckoutActive,
  fontFamily,
  colorCheckoutCellBackground,
  colorError,
  colorLines,
  formErrors,
  firstName,
  lastName,
  phoneNumber,
  phoneNumberLocale,
  dialCode,
  email,
  note,
  venueSpecificMarketingOn,
  venueGroupMarketingOn,
  tailoredCommunicationOn,
  venueSmsMarketingOn,
  textFirstName,
  textLastName,
  textEmailAddress,
  textPhoneNumber,
  textAdditionalNotes,
  infoFields,
  changeFormField,
  changePhoneNum,
  changeFlag,
  toggleModalDisplay,
  toggleField,
  textAgreeToWaitlistPolicy,
  textVenueSpecificMarketingOptIn,
  textVenueGroupMarketingOptIn,
  textTailoredCommunicationOptInLabel,
  textVenueSmsMarketingOptIn,
  textCustomPolicyHolderName,
  textCustomPrivacyPolicyLink,
  textCustomPrivacyPolicyLinkLabel,
  textReservationSmsOptIn,
  termsPolicyHolderName,
  termsOfServiceUrl,
  termsOfServiceText,
  gdprPolicyText,
  agreedToWaitlistPolicy,
  agreedToVenueSpecificMarketingOptIn,
  agreedToVenueGroupMarketingOptIn,
  agreedToVenueSmsMarketingOptIn,
  agreedToTailoredCommunicationOptIn,
  textCustomGdprPolicyLink,
  textCustomGdprPolicyLinkLabel,
  waitlistTextWidgetAgreeToTermsLabel,
  textAnd,
  waitlistWidgetBlankErrorText,
  waitlistWidgetInvalidErrorText,
  policyUsTwilioSmsOptInSubfooter,
  hideCountryFlags,
  displayReservationSmsOptInPolicy,
  agreedToReservationSmsOptIn,
  isWhatsappEnabled,
  preferredMessagingChannel,
}) => {
  const textInputProps = {
    fontFamily,
    placeholderColor: fontsColorCheckoutInactive,
    textColor: fontsColorCheckoutActive,
    errorColor: colorError,
  }
  const infoIconStyle = {
    color: fontsColorCheckoutActive,
  }
  const buildTermsAnchorTag = (href, text) => (
    <a style={[styles.link, { color: fontsColorLinks }]} target="_blank" rel="noreferrer noopener" href={href} key={href + text}>
      {text}
    </a>
  )
  const groupTermsOfServiceAnchor = buildTermsAnchorTag(termsOfServiceUrl, termsOfServiceText)
  const gdprAnchor = !_.isEmpty(gdprPolicyText) && buildTermsAnchorTag(textCustomGdprPolicyLink, gdprPolicyText)
  const isSevenRoomsGdprPolicy = is7RGdprPolicyRegex.test(textCustomGdprPolicyLink)
  const hasCustomPrivacyPolicy = !_.isEmpty(textCustomPrivacyPolicyLink)
  const customPrivacyPolicyAnchor =
    hasCustomPrivacyPolicy && buildTermsAnchorTag(textCustomPrivacyPolicyLink, textCustomPrivacyPolicyLinkLabel)
  const onAgreedToWaitlistPolicyClick = () => {
    toggleField('agreedToWaitlistPolicy')
  }
  const onVenueGroupMarketingOptInClick = () => {
    toggleField('agreedToVenueGroupMarketingOptIn')
  }
  const onVenueSpecificMarketingOptInClick = () => {
    toggleField('agreedToVenueSpecificMarketingOptIn')
  }
  const onVenueSmsMarketingOptInClick = () => {
    toggleField('agreedToVenueSmsMarketingOptIn')
  }
  const onTailoredCommunicationOptInClick = () => {
    toggleField('agreedToTailoredCommunicationOptIn')
  }
  const onReservationSmsOptInClick = () => {
    toggleField('agreedToReservationSmsOptIn')
  }
  const handlePreferredMessagingChannelChange = evt => {
    changeFormField('preferredMessagingChannel', evt.target.value)
  }
  const toggleReservationSmsOptInPolicyDisplay = () => {
    toggleModalDisplay(modalTypes.RESERVATION_SMS_OPT_IN_POLICY)
  }
  const getCheckBoxElement = (value, iconColor, required = false) => {
    let isChecked
    let clickFunc
    switch (value) {
      case 'agreedToWaitlistPolicy':
        isChecked = agreedToWaitlistPolicy
        clickFunc = onAgreedToWaitlistPolicyClick
        break
      case 'agreedToVenueGroupMarketingOptIn':
        isChecked = agreedToVenueGroupMarketingOptIn
        clickFunc = onVenueGroupMarketingOptInClick
        break
      case 'agreedToVenueSpecificMarketingOptIn':
        isChecked = agreedToVenueSpecificMarketingOptIn
        clickFunc = onVenueSpecificMarketingOptInClick
        break
      case 'agreedToVenueSmsMarketingOptIn':
        isChecked = agreedToVenueSmsMarketingOptIn
        clickFunc = onVenueSmsMarketingOptInClick
        break
      case 'agreedToTailoredCommunicationOptIn':
        isChecked = agreedToTailoredCommunicationOptIn
        clickFunc = onTailoredCommunicationOptInClick
        break
      case 'agreedToReservationSmsOptIn':
        isChecked = agreedToReservationSmsOptIn
        clickFunc = onReservationSmsOptInClick
        break
      default:
        isChecked = false
    }
    const icon = isChecked ? <i style={[styles.checkIcon, { color: iconColor }]} className="fa fa-check fa-fw" aria-hidden="true" /> : null

    return (
      // eslint-disable-next-line react/button-has-type, jsx-a11y/role-supports-aria-props
      <button
        style={[styles.checkbox, { borderColor: iconColor }]}
        onClick={e => {
          e.preventDefault()
          clickFunc()
        }}
        aria-required={required}
        aria-label={_.startCase(value)}
      >
        {icon}
      </button>
    )
  }

  return (
    <FormContainer>
      <div style={[styles.infoForm, { backgroundColor: colorCheckoutCellBackground }]}>
        <form autoComplete="on" className="contact-form">
          <div style={[styles.formLine, styles.topBorderRadius]}>
            <div style={[styles.formBox]}>
              <TextInput
                ariaRequired
                placeholder={`${textFirstName} *`}
                limitType="name"
                value={firstName}
                charLimit={50}
                validator={validateNotEmpty}
                isValid={formErrors.firstName}
                onChange={val => changeFormField('firstName', val)}
                style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
                ariaLabel={ariaLabelBlankFeedback(textFirstName, formErrors.firstName, waitlistWidgetBlankErrorText)}
                autoComplete="given-name"
                ref={input => {
                  // eslint-disable-next-line no-param-reassign
                  infoFields.firstName = input
                }}
                inputId="sr-first-name *"
                testId="sr-first-name"
                {...textInputProps}
              />
            </div>
            <div style={[styles.formBox]}>
              <TextInput
                ariaRequired
                placeholder={`${textLastName} *`}
                limitType="name"
                value={lastName}
                charLimit={50}
                validator={validateNotEmpty}
                isValid={formErrors.lastName}
                onChange={val => changeFormField('lastName', val)}
                style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
                autoComplete="family-name"
                ariaLabel={ariaLabelBlankFeedback(textLastName, formErrors.lastName, waitlistWidgetBlankErrorText)}
                ref={input => {
                  // eslint-disable-next-line no-param-reassign
                  infoFields.lastName = input
                }}
                inputId="sr-last-name *"
                testId="sr-last-name"
                {...textInputProps}
              />
            </div>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div style={[styles.formLine]}>
            <TextInput
              ariaRequired
              placeholder={`${textEmailAddress} *`}
              limitType="email"
              value={email}
              charLimit={100}
              validator={validateEmail}
              isValid={formErrors.email}
              onChange={val => changeFormField('email', val)}
              style={styles.singleFieldInput}
              ariaLabel={ariaLabelFormatFeedback(
                textEmailAddress,
                formErrors.email,
                email,
                waitlistWidgetInvalidErrorText,
                waitlistWidgetBlankErrorText
              )}
              autoComplete="email"
              ref={input => {
                // eslint-disable-next-line no-param-reassign
                infoFields.email = input
              }}
              inputId="sr-email-address *"
              testId="sr-email-address"
              {...textInputProps}
            />
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />

          <div
            style={[
              styles.formLine,
              (_.isEmpty(formErrors) ? true : formErrors.phoneNumber)
                ? null
                : {
                    borderColor: colorError,
                    borderStyle: 'solid',
                    borderWidth: '2px',
                  },
              { paddingTop: '8px', paddingBottom: '8px' },
            ]}
          >
            <div>
              <PhoneNumber
                ariaRequired
                onPhoneNumberChange={changePhoneNum}
                onFlagChange={changeFlag}
                customStyles={[styles.phoneNumberInputContainer]}
                dialCode={dialCode}
                fontsColorCheckoutActive={fontsColorCheckoutActive}
                fontsColorCheckoutInactive={fontsColorCheckoutInactive}
                fontFamily={fontFamily}
                isValid={_.isEmpty(formErrors) ? true : formErrors.phoneNumber}
                ariaLabel={ariaLabelFormatFeedback(
                  textPhoneNumber,
                  formErrors.phoneNumber,
                  phoneNumber,
                  waitlistWidgetInvalidErrorText,
                  waitlistWidgetBlankErrorText
                )}
                phoneNumber={phoneNumber}
                selectedCountryCode={phoneNumberLocale}
                placeholderText={textPhoneNumber}
                labelText={textPhoneNumber}
                autoComplete="random-bits-of-words-to-break-autocomplete"
                ref={input => {
                  // eslint-disable-next-line no-param-reassign
                  infoFields.phoneNumber = input
                }}
                hideFlags={hideCountryFlags}
                testId="sr-phone-number"
                {...textInputProps}
              />
            </div>
          </div>

          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div style={[styles.formLine, { paddingTop: '8px', paddingBottom: '8px' }]}>
            <TextAreaInput
              value={note}
              placeholder={textAdditionalNotes}
              style={styles.singleFieldInput}
              inputStyle={{ resize: 'none' }}
              onChange={val => changeFormField('note', val)}
              inputId="sr-additional-notes"
              dataTest="sr-additional-notes"
              {...textInputProps}
            />
          </div>

          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />

          <div
            style={[
              styles.formLine,
              styles.singleFieldInput,
              {
                color: formErrors.agreedToWaitlistPolicy ? colorError : fontsColorCheckoutActive,
              },
              {
                display: 'flex',
                alignItems: 'center',
                paddingTop: '8px',
                paddingBottom: '8px',
                height: 'auto',
              },
            ]}
          >
            {getCheckBoxElement('agreedToWaitlistPolicy', fontsColorCheckoutActive)}
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })} onClick={onAgreedToWaitlistPolicyClick}>
              {textAgreeToWaitlistPolicy}
            </label>
            <button
              style={{
                ...styles.infoIcon,
                ...styles.buttonInfoIcon,
                ...infoIconStyle,
              }}
              className="fa fa-info-circle fa-fw"
              onClick={e => {
                e.preventDefault()
                toggleModalDisplay(modalTypes.WAITLIST_AGREE_POLICY)
              }}
              aria-hidden="true"
              aria-label="agree to waitlist policy info"
              type="button"
            />
          </div>

          {displayReservationSmsOptInPolicy && (
            <div
              style={[
                styles.formLine,
                styles.singleFieldInput,
                {
                  color: formErrors.agreedToReservationSmsOptIn ? colorError : fontsColorCheckoutActive,
                },
                {
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  height: 'auto',
                },
              ]}
            >
              {getCheckBoxElement('agreedToReservationSmsOptIn', fontsColorCheckoutActive)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span style={{ ...styles.policyLine, width: '78%' }}>
                <span style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })} onClick={onReservationSmsOptInClick}>
                  <FormattedMessage
                    id="textReservationSmsOptIn"
                    // eslint-disable-next-line formatjs/enforce-default-message
                    defaultMessage={textReservationSmsOptIn}
                    values={{
                      i: chunks => <i>{chunks}</i>,
                    }}
                  />
                  *
                </span>
                {isWhatsappEnabled && agreedToReservationSmsOptIn && (
                  <>
                    <label
                      style={{ ...styles.policyLine, width: '78%', margin: '12px 0 0 0', display: 'flex', alignItems: 'center' }}
                      htmlFor="whatsapp"
                    >
                      <input
                        type="radio"
                        id="whatsapp"
                        name="preferredMessagingChannel"
                        value="WHATSAPP"
                        style={{ margin: '0 8px 0 0' }}
                        onChange={handlePreferredMessagingChannelChange}
                        checked={preferredMessagingChannel === 'WHATSAPP'}
                      />
                      <FormattedMessage {...intlWidgetMessages.messageChannelOptionWhatsApp} />
                    </label>
                    <label
                      style={{ ...styles.policyLine, width: '78%', margin: '8px 0 0 0', display: 'flex', alignItems: 'center' }}
                      htmlFor="sms_mms"
                    >
                      <input
                        type="radio"
                        id="sms_mms"
                        name="preferredMessagingChannel"
                        value="SMS_MMS"
                        style={{ margin: '0 8px 0 0' }}
                        onChange={handlePreferredMessagingChannelChange}
                        checked={preferredMessagingChannel === 'SMS_MMS'}
                      />
                      <FormattedMessage {...intlWidgetMessages.messageChannelOptionSMS} />
                    </label>
                  </>
                )}
              </span>
              <button
                type="button"
                style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                className="fa fa-info-circle fa-fw"
                onClick={toggleReservationSmsOptInPolicyDisplay}
                aria-hidden="true"
                aria-label="SMS opt in policy info"
              />
            </div>
          )}

          {venueSmsMarketingOn && (
            <div
              style={[
                styles.formLine,
                styles.singleFieldInput,
                {
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '8px',
                  height: 'auto',
                  color: fontsColorCheckoutActive,
                },
              ]}
            >
              {getCheckBoxElement('agreedToVenueSmsMarketingOptIn', fontsColorCheckoutActive)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })}
                onClick={onVenueSmsMarketingOptInClick}
                dangerouslySetInnerHTML={{
                  __html: textVenueSmsMarketingOptIn,
                }}
              />
              <button
                style={{
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                  ...infoIconStyle,
                }}
                className="fa fa-info-circle fa-fw"
                onClick={e => {
                  e.preventDefault()
                  toggleModalDisplay(modalTypes.VENUE_SMS_MARKETING_OPT_IN_POLICY)
                }}
                aria-hidden="true"
                aria-label="venue text marketing policy info"
                type="button"
              />
            </div>
          )}

          {venueSpecificMarketingOn && (
            <div
              style={[
                styles.formLine,
                styles.singleFieldInput,
                {
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '8px',
                  height: 'auto',
                  color: fontsColorCheckoutActive,
                },
              ]}
            >
              {getCheckBoxElement('agreedToVenueSpecificMarketingOptIn', fontsColorCheckoutActive)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })}
                onClick={onVenueSpecificMarketingOptInClick}
                dangerouslySetInnerHTML={{
                  __html: textVenueSpecificMarketingOptIn,
                }}
              />
              <button
                style={{
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                  ...infoIconStyle,
                }}
                className="fa fa-info-circle fa-fw"
                onClick={e => {
                  e.preventDefault()
                  toggleModalDisplay(modalTypes.VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY)
                }}
                aria-hidden="true"
                aria-label="venue marketing policy info"
                type="button"
              />
            </div>
          )}

          {venueGroupMarketingOn && (
            <div
              style={[
                styles.formLine,
                styles.singleFieldInput,
                {
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '8px',
                  height: 'auto',
                  color: fontsColorCheckoutActive,
                },
              ]}
            >
              {getCheckBoxElement('agreedToVenueGroupMarketingOptIn', fontsColorCheckoutActive)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })}
                onClick={onVenueGroupMarketingOptInClick}
                dangerouslySetInnerHTML={{
                  __html: textVenueGroupMarketingOptIn,
                }}
              />
              <button
                style={{
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                  ...infoIconStyle,
                }}
                className="fa fa-info-circle fa-fw"
                onClick={e => {
                  e.preventDefault()
                  toggleModalDisplay(modalTypes.VENUE_GROUP_MARKETING_OPT_IN_POLICY)
                }}
                aria-hidden="true"
                aria-label="venue group marketing policy info"
                type="button"
              />
            </div>
          )}

          {tailoredCommunicationOn && (
            <div
              style={[
                styles.formLine,
                styles.singleFieldInput,
                {
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '8px',
                  height: 'auto',
                  color: fontsColorCheckoutActive,
                },
              ]}
            >
              {getCheckBoxElement('agreedToTailoredCommunicationOptIn', fontsColorCheckoutActive)}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                style={(styles.policyLine, { flex: '1', maxWidth: 'none', fontSize: '13px' })}
                onClick={onTailoredCommunicationOptInClick}
                dangerouslySetInnerHTML={{
                  __html: textTailoredCommunicationOptInLabel,
                }}
              />
              <button
                style={{
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                  ...infoIconStyle,
                }}
                className="fa fa-info-circle fa-fw"
                onClick={e => {
                  e.preventDefault()
                  toggleModalDisplay(modalTypes.TAILORED_COMMUNICATION_OPT_IN_POLICY)
                }}
                aria-hidden="true"
                aria-label="Tailored Communication opt-in policy info"
                type="button"
              />
            </div>
          )}

          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />

          <div style={[styles.footerText, { color: fontsColorPrimary }]}>
            {/* See WB-1546 for terms/policy formatting/language */}
            {`${waitlistTextWidgetAgreeToTermsLabel} ${termsPolicyHolderName} `}
            {/* eslint-disable-next-line no-nested-ternary */}
            {hasCustomPrivacyPolicy && isSevenRoomsGdprPolicy
              ? [
                  groupTermsOfServiceAnchor,
                  gdprAnchor && ` ${textAnd} `,
                  gdprAnchor,
                  ` ${textAnd} ${textCustomPolicyHolderName} `,
                  customPrivacyPolicyAnchor,
                ]
              : hasCustomPrivacyPolicy
              ? [
                  groupTermsOfServiceAnchor,
                  ` ${textAnd} ${textCustomPolicyHolderName} `,
                  customPrivacyPolicyAnchor,
                  gdprAnchor && ` ${textAnd} `,
                  gdprAnchor,
                ]
              : [groupTermsOfServiceAnchor, gdprAnchor && ` ${textAnd} `, gdprAnchor]}
          </div>
          {agreedToVenueSmsMarketingOptIn && (
            <div style={[styles.footerText, { color: fontsColorPrimary }]}>
              <IntlProvider locale="en">
                <FormattedMessage
                  id="policyUsTwilioSmsOptInSubfooter"
                  // eslint-disable-next-line formatjs/enforce-default-message
                  defaultMessage={policyUsTwilioSmsOptInSubfooter}
                  values={{
                    i: chunks => <i>{chunks}</i>,
                  }}
                />
              </IntlProvider>
            </div>
          )}
        </form>
      </div>
    </FormContainer>
  )
}

export default Radium(WaitlistForm)
