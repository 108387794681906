import { Map, List } from 'immutable'
import moment from 'moment-timezone'
import {
  ADVANCE_STAGE,
  CANCEL_WAITLIST_ENTRY_FAIL,
  CANCEL_WAITLIST_ENTRY_START,
  CANCEL_WAITLIST_ENTRY_SUCCESS,
  CLEAR_REDEMPTIONS_ON_PROMO_CODE,
  CONFIRM_TIME_SLOT,
  DECREMENT_CALENDAR_MONTH,
  DISMISS_MODAL,
  DISPLAY_MODAL,
  GET_EXPERIENCE_AVAILABILITY_DONE,
  GET_EXPERIENCE_AVAILABILITY_SUCCESS,
  GET_EXPERIENCE_START,
  GET_INITIAL_AVAILABILITY_SUCCESS,
  GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
  GET_PROMO_CODES_FAILURE,
  GET_REMAINING_LANGUAGE_STRINGS_FAILURE,
  GET_REMAINING_LANGUAGE_STRINGS_START,
  GET_REMAINING_LANGUAGE_STRINGS_SUCCESS,
  GET_RESERVATION_REQUEST_START,
  GET_RESERVATION_REQUEST_FAIL,
  GET_WAITLIST_ETAS_FAIL,
  GET_WAITLIST_ETAS_START,
  GET_WAITLIST_ETAS_SUCCESS,
  GO_TO_WAITING_ROOM,
  HIDE_LOADING,
  HOLD_RESERVATION_FAILURE,
  HOLD_RESERVATION_SUCCESS,
  INCREMENT_CALENDAR_MONTH,
  LOAD_ACTUAL_FAIL,
  LOAD_ACTUAL_START,
  LOAD_ACTUAL_SUCCESS,
  LOAD_LANDING_PAGE_START,
  LOAD_LANDING_PAGE_SUCCESS,
  PASS_STRIPE_CARD_ELEMENT,
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_SUCCESS,
  POST_REQUEST_FAILURE,
  POST_CHECKOUT_RELOAD_ON_FAILURE,
  POST_REQUEST_SUCCESS,
  REJOIN_WAITLIST,
  REVERT_STAGE,
  SAVE_TAG_GROUP,
  SELECT_QUERY_DATE,
  SELECT_QUERY_DURATION,
  SELECT_QUERY_PARTY_SIZE,
  SELECT_QUERY_TIME,
  SELECT_REQUEST_END_TIME,
  SELECT_REQUEST_TIME,
  SELECT_REQUEST,
  SELECT_TIME_SLOT,
  SET_LOGIN_INFO,
  STRIPE_ERROR,
  SUBMIT_WAITLIST_FAIL,
  SUBMIT_WAITLIST_START,
  SUBMIT_WAITLIST_SUCCESS,
  TOGGLE_SEARCH_COMPONENT,
  TRY_AGAIN,
  TRY_GET_INITIAL_AVAILABILITY,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
  TRY_HOLD_RESERVATION,
  TRY_POST_CHECKOUT,
  TRY_POST_REQUEST,
  TRY_REDIRECT,
  TRY_VIEW_REDEMPTION_BALANCE,
  VERIFY_PROMO_CODE_FAILURE,
  VIEW_REDEMPTION_BALANCE_LOADED,
  INITIALIZE_UI,
  DISABLE_INPUTS_AND_SUBMIT,
} from '../actions/ActionTypes'
import * as AnalyticsEvents from '../utils/analyticsEvents'
import { doShowDiningAdditionalSelectionModal } from '../utils/conditions'
import { modalTypes, viewTypes } from '../utils/constantTypes'

const ui = (state = Map(), action) => {
  switch (action.type) {
    case INITIALIZE_UI:
      return state.merge(action.ui)
    case SET_LOGIN_INFO:
      return action.status === 'guest'
        ? state.update('stage', stage => stage + 1)
        : state.update('viewSequence', viewSequence =>
            viewSequence.reduce((result, view) => (view === viewTypes.LOGIN ? result : result.concat(view)), List())
          )
    case SELECT_TIME_SLOT: {
      const {
        publicLongFormDescription,
        publicDescriptionTitle,
        publicPhoto,
        timeSlotHasUpsells,
        isSocialMediaLoginEnabled,
        isInstantExperience,
        isModifyResMode,
        isRequestBookingMode,
        selectedAutomaticUpsells,
        hasClientToken,
      } = action

      const newState = state.update('viewSequence', viewSequence =>
        viewSequence.reduce((result, view, index) => {
          if (view === viewTypes.REQUEST) {
            return result.concat(viewTypes.CHECKOUT)
          }

          if (hasClientToken && view === viewTypes.LOGIN) {
            return result
          }

          if (timeSlotHasUpsells && viewSequence.get(index + 1) !== viewTypes.UPSELLS) {
            if (view === viewTypes.SEARCH_RESULTS) {
              return result.concat(viewTypes.SEARCH_RESULTS, viewTypes.UPSELLS)
            } else if (view === viewTypes.EXPERIENCES) {
              return result.concat(viewTypes.EXPERIENCES, viewTypes.UPSELLS)
            }
          }

          if (
            (!timeSlotHasUpsells && view === viewTypes.UPSELLS) ||
            (!isSocialMediaLoginEnabled && view === viewTypes.LOGIN) ||
            (isInstantExperience && view === viewTypes.LOGIN) ||
            (isModifyResMode && view === viewTypes.LOGIN) ||
            (isRequestBookingMode && view === viewTypes.LOGIN)
          ) {
            return result
          }

          return result.concat(view)
        }, List())
      )

      if (doShowDiningAdditionalSelectionModal(publicLongFormDescription, publicDescriptionTitle, publicPhoto, selectedAutomaticUpsells)) {
        return newState.merge({
          displayModalType: modalTypes.DINING_ADDITIONAL_SELECTION,
        })
      }
      return newState.update('stage', stage => stage + 1)
    }
    case CONFIRM_TIME_SLOT:
      return state.update('stage', stage => stage + 1)
    case ADVANCE_STAGE:
      return state.update('stage', stage => stage + 1)
    case SELECT_REQUEST: {
      const newState = state.update('viewSequence', viewSequence =>
        viewSequence.reduce((result, view, index) => {
          if (!action.isSocialMediaLoginEnabled && view === viewTypes.LOGIN) {
            return result
          }
          if (view === viewTypes.CHECKOUT) {
            view = viewTypes.REQUEST
          }
          if (view === viewTypes.UPSELLS) {
            // Filter out if upsell step inserted
            return result
          }
          return result.concat(view)
        }, List())
      )
      return newState.update('stage', stage => stage + 1)
    }
    case REVERT_STAGE:
      return state.update('stage', stage => Math.max(stage - 1, 0))
    case TRY_GET_MULTI_VENUE_AVAILABILITY:
    case TRY_GET_INITIAL_AVAILABILITY:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || window.widgetInit.settings.text_finding,
      })
    case LOAD_LANDING_PAGE_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Loading Landing Page details',
      })
    case LOAD_LANDING_PAGE_SUCCESS:
      return state.merge({
        displayModalType: undefined,
      })
    case LOAD_ACTUAL_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || 'Loading reservation details',
      })
    case LOAD_ACTUAL_SUCCESS:
      return state.update('displayModalType', () => null)
    case SELECT_REQUEST_TIME:
    case SELECT_REQUEST_END_TIME:
    case SELECT_QUERY_PARTY_SIZE:
    case SELECT_QUERY_DURATION:
    case SELECT_QUERY_TIME:
    case SELECT_QUERY_DATE:
      return state.set('displaySearchType', null)
    case GET_MULTI_VENUE_AVAILABILITY_SUCCESS:
      if (action.isModifyResUpgradesMode) {
        return state.merge({
          displayModalType: null,
          stage: 0,
        })
      }
      return state.get('stage') === 0
        ? state.merge({
            displayModalType: null,
            stage: action.isExperienceMode ? 0 : 1,
          })
        : state
    case GET_INITIAL_AVAILABILITY_SUCCESS:
      return state.merge({
        displayModalType: null,
        stage: action.isExperienceMode || action.isModifyResUpgradesMode ? 0 : 1,
      })
    case GET_RESERVATION_REQUEST_FAIL:
    case STRIPE_ERROR:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Ok',
        errorColor: action.errorColor || 'red',
        actionCallback: action.actionCallback || (() => null),
      })
    case DISABLE_INPUTS_AND_SUBMIT:
      return state.set('disableInputsAndSubmit', true)
    case TRY_POST_REQUEST:
    case TRY_POST_CHECKOUT:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || window.widgetInit.settings.text_processing,
      })
    case TRY_REDIRECT:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Redirecting you to vendor',
      })
    case TRY_HOLD_RESERVATION:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: window.widgetInit.settings.text_securing_hold,
      })
    case TRY_VIEW_REDEMPTION_BALANCE:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Checking gift card balance',
      })
    case VIEW_REDEMPTION_BALANCE_LOADED:
      return state.set('displayModalType', null)
    case POST_REQUEST_SUCCESS: {
      let newState = state.update('stage', stage => stage + 1)
      newState = newState.set('displayModalType', null)
      return newState.set('isRequest', true)
    }
    case POST_CHECKOUT_SUCCESS: {
      const newState = state.update('stage', stage => stage + 1)
      AnalyticsEvents.reservationConfirmation()
      return newState.set('displayModalType', null)
    }
    case HOLD_RESERVATION_SUCCESS:
      return state.set('displayModalType', null)
    case POST_CHECKOUT_RELOAD_ON_FAILURE:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Reload',
        actionCallback: action.actionCallback || (() => null),
      })
    case POST_REQUEST_FAILURE:
    case POST_CHECKOUT_FAILURE:
    case HOLD_RESERVATION_FAILURE:
    case LOAD_ACTUAL_FAIL:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Ok',
        errorColor: action.errorColor || 'red',
        actionCallback: action.actionCallback || (() => null),
      })

    case CLEAR_REDEMPTIONS_ON_PROMO_CODE:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: 'A promotion has been applied and the amount due has been adjusted. Please re-apply your gift card',
        actionText: action.actionText || 'Ok',
        errorColor: action.errorColor || 'red',
        actionCallback: action.actionCallback || (() => null),
      })
    case GET_PROMO_CODES_FAILURE:
    case GET_WAITLIST_ETAS_FAIL:
    case SUBMIT_WAITLIST_FAIL:
    case CANCEL_WAITLIST_ENTRY_FAIL:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Ok',
        errorColor: action.errorColor || 'red',
        actionCallback: action.actionCallback || (() => null),
      })
    case REJOIN_WAITLIST:
      const rejoinWaitlistState = state.update('stage', stage => stage - 2)
      return rejoinWaitlistState.merge({ displayModalType: null })
    case SAVE_TAG_GROUP:
    case DISMISS_MODAL:
      return state.set('displayModalType', null)
    case DISPLAY_MODAL:
      return state.update('displayModalType', val => (val === action.modal ? null : action.modal))
    case TOGGLE_SEARCH_COMPONENT:
      return state.update('displaySearchType', val => (val === action.item ? null : action.item))
    case VERIFY_PROMO_CODE_FAILURE:
      if (state.autoAddedCreditCardPromoCode) {
        return state
      }
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Ok',
        errorColor: action.errorColor || 'red',
        actionCallback: action.actionCallback || (() => null),
      })
    case TRY_AGAIN:
      return state.update('stage', stage => 0)
    case GET_EXPERIENCE_AVAILABILITY_SUCCESS:
      if (action.firstAvailableExperienceDate) {
        return state
      }
      return action.data.firstAvailable ? state.set('calendarDateMoment', moment(action.data.firstAvailable).date(1)) : state
    case GET_EXPERIENCE_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Loading Availability',
      })
    case GET_EXPERIENCE_AVAILABILITY_DONE:
    case CANCEL_WAITLIST_ENTRY_SUCCESS:
      return state.merge({ displayModalType: null })
    case INCREMENT_CALENDAR_MONTH:
      return state.update('calendarDateMoment', prevMoment => prevMoment.clone().add(1, 'months'))
    case DECREMENT_CALENDAR_MONTH:
      return state.update('calendarDateMoment', prevMoment => prevMoment.clone().subtract(1, 'months'))
    case PASS_STRIPE_CARD_ELEMENT:
      return state.merge({ stripeCardElement: action.element })
    case GET_WAITLIST_ETAS_START:
      if (state.get('displayModalType') === modalTypes.ERROR_DISPLAY) {
        return state
      }
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || 'Loading Waitlist',
      })
    case GET_WAITLIST_ETAS_SUCCESS:
      if (state.get('displayModalType') === modalTypes.ERROR_DISPLAY) {
        return state
      }
      let waitlistState = state
      if (action.viewType === viewTypes.CHOOSE_WAITLIST_EXPERIENCE) {
        waitlistState = state.update('stage', stage => stage + 1)
      }
      if (action.viewType === viewTypes.WAITLIST_CLOSED) {
        waitlistState = state.update('stage', stage => stage + 3)
      }
      if (action.viewType === viewTypes.VENUE_CLOSED) {
        waitlistState = state.update('stage', stage => stage + 4)
      }
      return waitlistState.merge({ displayModalType: null })
    case SUBMIT_WAITLIST_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || 'Adding to Waitlist',
      })
    case GO_TO_WAITING_ROOM:
    case SUBMIT_WAITLIST_SUCCESS:
      const newState = state.update('stage', stage => stage + 2)
      return newState.merge({ displayModalType: null })
    case CANCEL_WAITLIST_ENTRY_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinnerModalMessage || 'Removing from Waitlist',
      })
    case HIDE_LOADING:
      return state.merge({
        displayModalType: undefined,
      })
    case GET_REMAINING_LANGUAGE_STRINGS_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Getting reservation details',
      })
    case GET_REMAINING_LANGUAGE_STRINGS_SUCCESS:
    case GET_REMAINING_LANGUAGE_STRINGS_FAILURE:
      return state.set('displayModalType', null)
    case GET_RESERVATION_REQUEST_START:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: 'Loading Request details',
      })
    default:
      return state
  }
}

export default ui
