/* eslint jsx-a11y/no-static-element-interactions: 0 */
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import styles from '../assets/styles/calendar'

const TDComponent = props => <td {...props} />
const AccessibleTDComponent = accessibleComponentWrapper(TDComponent, false)

class CalendarCell extends Component {
  constructor(props) {
    super(props)

    const { selectDate, cellMoment } = this.props
    if (selectDate && cellMoment) {
      this.onDateClick = this.props.selectDate.bind(this, this.props.cellMoment.format('YYYY-MM-DD'))
    }
  }

  render() {
    const {
      cellMoment,
      currentMoment,
      isSelected,
      isAvailable,
      colorHover,
      colorCellBackground,
      fontsColorInactive,
      fontsColorPrimary,
      colorPrimary,
      fontsColorPrimaryLightOpacity,
      fontsColorPrimaryDarkOpacity,
      inactiveTextOpacity,
      fontColorButton,
    } = this.props
    const hasValue = !!(cellMoment && currentMoment)
    const isToday = hasValue && currentMoment.isSame(cellMoment, 'day')
    // stripping rgb from rgba to change alpha opacity
    const matchColors = /rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), (0(\.\d+)?|1)/
    const rgbaColors = matchColors.exec(colorPrimary)
    const rgbColor = `rgba(${rgbaColors[1]}, ${rgbaColors[2]}, ${rgbaColors[3]}, `
    const fontsColorPrimaryOpacity = colorCellBackground === 'rgba(255, 255, 255, 1)' ? `${rgbColor}0.2)` : `${rgbColor}0.4)`
    const cellAria = cellMoment && isAvailable ? `${cellMoment.format('dddd, MMMM Do YYYY')}, Available` : ''
    return (
      <AccessibleTDComponent
        style={_.assign(
          {},
          styles.cellWrapper,
          isAvailable && styles.availCell,
          isAvailable
            ? { backgroundColor: colorCellBackground, color: fontsColorPrimary }
            : { color: fontsColorInactive, opacity: inactiveTextOpacity },
          isSelected && {
            ...styles.selectedDate,
            backgroundColor: colorPrimary,
            color: fontColorButton,
          },
          isAvailable && {
            ':hover': { backgroundColor: fontsColorPrimaryOpacity },
          },
          isToday && { ...styles.todayCell }
        )}
        onClick={isAvailable ? this.onDateClick : undefined}
        tabIndex={isAvailable && '0'}
        role={isAvailable && 'button'}
        aria-label={cellAria}
      >
        {hasValue && cellMoment.format('D')}
      </AccessibleTDComponent>
    )
  }
}

CalendarCell.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  cellMoment: React.PropTypes.object,
  currentMoment: React.PropTypes.object,
  /* eslint-enable */
  isSelected: React.PropTypes.bool,
  isAvailable: React.PropTypes.bool,
  selectDate: React.PropTypes.oneOfType([React.PropTypes.object, React.PropTypes.func]),
  colorHover: React.PropTypes.string,
  colorCellBackground: React.PropTypes.string,
  fontsColorInactive: React.PropTypes.string,
  fontsColorPrimary: React.PropTypes.string,
  colorPrimary: React.PropTypes.string,
  fontColorButton: React.PropTypes.string,
}

CalendarCell.defaultProps = {
  cellMoment: null,
  currentMoment: null,
  isSelected: false,
  isAvailable: false,
  selectDate: null,
  colorHover: 'rgba(50, 70, 84, 1.00)',
  fontsColorPrimary: 'rgba(50, 70, 84, 1.00)',
  fontsColorPrimaryLightOpacity: 'rgba(50, 70, 84, 0.2)',
  fontsColorPrimaryDarkOpacity: 'rgba(50, 70, 84, 0.4)',
  colorCellBackground: 'rgba(50, 70, 84, 1.00)',
  fontsColorInactive: 'rgba(50, 70, 84, 0.3)',
  inactiveTextOpacity: '0.50',
  colorPrimary: 'rgba(50, 70, 84, 1.00)',
}

export default Radium(CalendarCell)
